"use client";
import Link from "next/link";

export default function NotFound() {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white-100">
      <h2 className="text-2xl font-bold mb-2 text-gray-800">
        Oops! Page Missing.
      </h2>
      <p className="mb-6 text-lg text-gray-600">
        {"We can't seem to find the page you're looking for."}
      </p>
      <Link
        href="/"
        className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
      >
        Take Me Home
      </Link>
    </div>
  );
}
